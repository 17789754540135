import { NgModule } from '@angular/core';
import {
  COMMON_JSS_IMPORTS,
  COMMON_JSS_LAZY_IMPORTS,
  CoreModulesCommonClientModule,
  CoreModulesCommonModule,
} from '@innogy/core/modules/common';
import {
  CoreModulesOpenModule,
  OPEN_JSS_IMPORTS,
  OPEN_JSS_LAZY_IMPORTS,
} from '@innogy/core/modules/open';
import { EnergiedirectIconModule } from '@innogy/icons';
import { NavigationEnergiedirectOpenModule } from '@innogy/navigation/ed-open';
import { UtilsModule } from '@innogy/utils/deprecated';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { HEADER_HEIGHT } from '@innogy/config';

import { EnergieDirectAppComponent } from './app.component';

export const DEPLOY_URL_VALUE = 'dist/energiedirect/browser';
export const DEPLOY_URL_PROVIDER = {
  provide: 'DEPLOY_URL',
  useValue: DEPLOY_URL_VALUE,
};

export const HEADER_HEIGHT_PROVIDER = {
  provide: HEADER_HEIGHT,
  useValue: 120,
};

export const sharedImports = [
  CoreModulesCommonModule.forRoot({
    appName: 'energiedirect',
    basePath: '',
  }),
  CoreModulesOpenModule,
  EnergiedirectIconModule,
  NavigationEnergiedirectOpenModule,
  JssModule.withComponents(
    [...COMMON_JSS_IMPORTS, ...OPEN_JSS_IMPORTS],
    [...COMMON_JSS_LAZY_IMPORTS, ...OPEN_JSS_LAZY_IMPORTS]
  ),
];

@NgModule({
  imports: [
    CoreModulesCommonClientModule.forRoot({ loaderColor: '#5fb624' }),
    CoreModulesCommonModule.forRoot({
      appName: 'energiedirect',
      basePath: '',
    }),
    UtilsModule.forRoot({
      brandColor: '#5fb624',
      brandColorDark: '#5fb624',
    }),
    ...sharedImports,
  ],
  declarations: [EnergieDirectAppComponent],
  providers: [DEPLOY_URL_PROVIDER, HEADER_HEIGHT_PROVIDER],
  bootstrap: [EnergieDirectAppComponent],
})
export class EnergieDirectAppModule {}
