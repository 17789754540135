<ng-progress *wlClientOnly aria-label="Aan het laden..."></ng-progress>
<wl-load-fonts font="OpenSans"></wl-load-fonts>
<wl-environment-classes></wl-environment-classes>
<wl-visitor-identification></wl-visitor-identification>
<wl-setup-anchor-scrolling></wl-setup-anchor-scrolling>
<ed-header></ed-header>
<router-outlet></router-outlet>
<wl-analytics-setup></wl-analytics-setup>
<wl-chatbot-iadvize [login]="false"></wl-chatbot-iadvize>
<wl-load-account *wlClientOnly></wl-load-account>
<wl-cookiewall-container></wl-cookiewall-container>
