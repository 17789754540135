import { NgModule } from '@angular/core';
import { UtilsModule } from '@innogy/utils/deprecated';

import { NavigationSharedModule } from './navigation.shared.module';
import { EdHeaderComponent } from './ed-header/ed-header.component';
import { NavigationEnergiedirectModule } from './navigation.ed.module';
import { EDMinimalHeaderContainerComponent } from './ed-header/ed-minimal-header/ed-minimal-header-container-component/ed-minimal-header-container.component';
import { EDMinimalHeaderComponent } from './ed-header/ed-minimal-header/ed-minimal-header.component';

@NgModule({
  imports: [NavigationSharedModule, NavigationEnergiedirectModule, UtilsModule],
  declarations: [
    EdHeaderComponent,
    EDMinimalHeaderContainerComponent,
    EDMinimalHeaderComponent,
  ],
  exports: [EdHeaderComponent],
})
export class NavigationEnergiedirectOpenModule {}
